import { ReactElement, useMemo } from 'react';
import React from 'react';

import { VegaFlex, VegaFont, VegaGrid, VegaLoadingIndicator } from '@heartlandone/vega-react';
import sortBy from 'lodash/sortBy';

import dashboardService from 'api/services/dashboard';
import ApplicationTracker from 'components/dashboard/applicationTracker';
import EquipmentTracker from 'components/dashboard/equipmentTracker';
import WelcomeCard from 'components/dashboard/welcomeCard';
import DiscoverProducts from 'components/discoverProducts';
import GrossSales from 'components/reports/grossSales';
import LastDeposit from 'components/reports/lastDeposit';
import PaymentTypeChart from 'components/reports/paymentType';
import TransactionChart from 'components/reports/transactions';
import useCustomDashboard from 'hooks/dashboard/useCustomDashboard';
import { CustomizeDashboardParam, DashboardComponent, MaxColumns, MaxRows } from 'types/dashboard';
import { DeviceIsMobile } from 'utility/showOnDevice';

import HelpCenterCard from '../helpCenterCard';
import SalesRepresentativeCard from '../salesRepresentativeCard';

const DashboardComponentDictionary = {
  [DashboardComponent.TransactionChart]: TransactionChart,
  [DashboardComponent.GrossSales]: GrossSales,
  [DashboardComponent.DiscoverProducts]: DiscoverProducts,
  [DashboardComponent.PaymentTypeChart]: PaymentTypeChart,
  [DashboardComponent.WelcomeCard]: WelcomeCard,
  [DashboardComponent.LastDeposit]: LastDeposit,
  [DashboardComponent.SalesRepresentativeCard]: SalesRepresentativeCard,
  [DashboardComponent.HelpCenterCard]: HelpCenterCard,
  [DashboardComponent.ApplicationTracker]: ApplicationTracker,
  [DashboardComponent.EquipmentTracker]: EquipmentTracker,
};

const CustomDashboard = ({ uniqueKey }: { uniqueKey: string }) => {
  const { isError, isLoading, data } = useCustomDashboard(uniqueKey);
  const isMobile = DeviceIsMobile();

  const dashboard = useMemo(() => {
    const grid: ReactElement[] = [];

    if (data != null && data?.components.length > 0) {
      const components = sortBy(data?.components, ['row', 'column']);

      for (const component of components) {
        const Component = DashboardComponentDictionary[component.name];

        grid.push(
          <div
            key={`${component.name}-${components.length}`}
            data-testid={`dashboard-${component.name}-${components.length}`}
            style={{
              gridColumn: `span ${isMobile ? MaxColumns : component.width}`,
              gridRow: `span ${component.height}`,
            }}
          >
            <Component
              saveCustomization={(componentName, configurationJSON) => {
                const param: CustomizeDashboardParam = {
                  componentName: componentName,
                  configurationJSON: configurationJSON,
                };
                dashboardService.CustomizeDashboard(param);
              }}
              initialConfigurationJSON={component.configurationJSON}
            />
          </div>,
        );
      }
    }
    return grid;
  }, [data, isMobile]);

  if (dashboard.length === 0 && !isLoading && !isError) return null;

  return (
    <>
      {isError && <VegaFont color="text-error">There was an error loading the dashboard.</VegaFont>}
      {isLoading && (
        <VegaFlex justifyContent={'center'} alignItems="center">
          <VegaLoadingIndicator data-testid="loading-indicator" />
        </VegaFlex>
      )}
      {!isLoading && (
        <VegaGrid
          gap={'size-24'}
          column={data?.maxColumns ?? (MaxColumns as any)}
          row={`${data?.maxRows ?? MaxRows}-auto` as any}
        >
          {dashboard}
        </VegaGrid>
      )}
    </>
  );
};

export default CustomDashboard;
