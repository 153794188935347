import React from 'react';

import { VegaCard, VegaFlex, VegaIcon, VegaFont } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import styles from './equipmentResourceCard.module.css';

interface equipmentResourceCardProps {
  equipmentName: string;
  resourceLink: string;
  dateShipped: string;
}

function EquipmentResourceCard({ equipmentName, resourceLink, dateShipped }: equipmentResourceCardProps) {
  const { t } = useTranslation('equipment-tracker');
  const resourceText = equipmentName + ' ' + t('resourceCardText');

  const date = new Date(dateShipped);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <a id={equipmentName + 'CardLink'} href={resourceLink} target="_blank">
      <VegaCard className={styles.card} variant={'border'} backgroundColor={'bg-quaternary'} padding={'size-8'}>
        <VegaFlex margin={'size-8'} direction={'row'}>
          <VegaFont className={styles.title} variant={'font-h6'}>
            {resourceText}
          </VegaFont>
        </VegaFlex>
        <VegaFlex className={styles.shippingDate} gap="size-8" alignItems="center" margin={'size-8'} direction={'row'}>
          <VegaIcon icon="fa fa-calendar" />
          <VegaFont color="text-secondary">{formattedDate}</VegaFont>
        </VegaFlex>
      </VegaCard>
    </a>
  );
}

export default EquipmentResourceCard;
