import React, { use } from 'react';

import { GetStaticProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import notificationService from 'api/services/notification';
import CustomDashboard from 'components/dashboard/customDashboard';
import MembersCard from 'components/dashboard/membersCard';
import LayoutCard from 'components/layout/layoutCard';
import { CanViewAddMembersCard } from 'components/user/auth/permissions';
import { UserContext } from 'components/user/auth/userContext';
import usePages from 'hooks/app/usePages';
import { LayoutTypes } from 'types/app';
import { CallToActionNames } from 'types/notification';
import { routes } from 'types/pages';
import { getServerSideTranslations } from 'utility/i18n/getServerSideTranslations';

const Dashboard = () => {
  const [uniqueKey] = React.useState<string>(crypto.randomUUID());
  const user = use(UserContext);
  const router = useRouter();
  const [showMembersCard, setShowMembersCard] = React.useState<boolean>(
    !sessionStorage.getItem(`${user.activeCustomer?.idValue}_memberCardDismissed`),
  );
  const { setPageTitle } = usePages();
  //Generate a unique key for the custom dashboard query.

  const handleOnDismiss = () => {
    notificationService.DismissCallToAction(CallToActionNames.AddNewUser).finally(() => {
      try {
        sessionStorage.setItem(`${user.activeCustomer?.idValue}_memberCardDismissed`, true.toString());
        setShowMembersCard(false);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleAddTeamMember = () => {
    router.push(routes.userManagement);
  };

  return (
    <React.Fragment>
      <Head>
        <title>{setPageTitle(routes.home)}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MembersCard
        isVisible={CanViewAddMembersCard(user) && showMembersCard}
        onAddTeamMember={handleAddTeamMember}
        onDismiss={handleOnDismiss}
      />
      <LayoutCard type={LayoutTypes.CardTransparent}>
        <CustomDashboard uniqueKey={uniqueKey} />
      </LayoutCard>
    </React.Fragment>
  );
};

export const getStaticProps: GetStaticProps<any> = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale, [
        'discover',
        'reports',
        'welcome-card',
        'sales-representative',
        'help-center',
        'members-card',
        'new-owners-card',
        'application-tracker',
        'equipment-tracker',
      ])),
    },
  };
};

export default Dashboard;
