import isEmpty from 'lodash/isEmpty';

import ShipmentDetails from 'types/equipmentTracker';

function areAllHelpArticleUrlsPopulated(shipments: ShipmentDetails[]): boolean {
  return shipments.some((s) => !isEmpty(s.helpArticleUrl));
}

export function displayShipmentReferences(shipments: ShipmentDetails[]): boolean {
  return shipments.length > 0 && areAllHelpArticleUrlsPopulated(shipments);
}
