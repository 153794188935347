import { ApplicationStatus } from 'components/dashboard/applicationTracker/applicationTracker.types';
import { CustomerAccountSettings } from 'types/accountSettings';
import {
  AllPermissionsResult,
  CustomerId,
  MDMCustomerAddress,
  PermissionSet,
  ResultSortDirection,
  User,
  UserContextApiResponse,
  UsersGridResult,
  UsersToImpersonateResult,
} from 'types/user';

import apiFunctions from '../..';

const UserUrl = '/api/v1/User/';

export const UserUrls = {
  GetUsersPermissions: UserUrl + 'GetUsersPermissions',
  SetCustomerAssignmentPermissions: UserUrl + 'SetCustomerAssignmentPermissions',
  GetAllPermissions: UserUrl + 'GetAllPermissions',
  InviteStaffMember: UserUrl + 'InviteStaffMember',
  GetUser: UserUrl + 'GetUser',
  GetImpersonateUser: UserUrl + 'GetImpersonateUser',
  GetAssignedUsers: UserUrl + 'GetAssignedUsers',
  GetFilteredAssignments: UserUrl + 'GetFilteredAssignments',
  GetUserManagementData: UserUrl + 'GetUserManagementData',
  GetOverrideTemplates: UserUrl + 'GetOverrideTemplates',
  SearchUsers: UserUrl + 'SearchUsers',
  SetActiveIdentifier: UserUrl + 'SetActiveIdentifier',
  UpdateCustomerAssignmentRoles: UserUrl + 'UpdateCustomerAssignmentRoles',
  UpdateAssignmentStatus: UserUrl + 'UpdateAssignmentStatus',
  SetUserPermissionOverrides: UserUrl + 'SetUserPermissionOverrides',
  getCustomerAddress: UserUrl + 'GetCustomerAddress',
  getCustomerAccountSettings: UserUrl + 'GetCustomerAccountSettings',
  getCustomerApplicationStatus: UserUrl + 'GetCustomerApplicationStatus',
  ValidateCustomerAssignmentInvitation: UserUrl + 'ValidateCustomerAssignmentInvitation',
  InviteUserCustomerAssignment: UserUrl + 'InviteUserCustomerAssignment',
  GetCustomerAssignmentStatusHistory: UserUrl + 'GetCustomerAssignmentStatusHistory',
  RemoveUserFromCustomer: UserUrl + 'RemoveUserFromCustomer',
};

async function GetUsersPermissions(email: string): Promise<PermissionSet> {
  return await apiFunctions.post(UserUrls.GetUsersPermissions, { email });
}

async function GetAllPermissions(): Promise<AllPermissionsResult> {
  return await apiFunctions.get(UserUrls.GetAllPermissions);
}

async function InviteStaffMember(
  email: string,
  firstName: string,
  lastName: string,
  targetPermissionSet: string,
  customPermissions: Array<string>,
) {
  return await apiFunctions.post(UserUrls.InviteStaffMember, {
    targetAccountEmail: email,
    firstName,
    lastName,
    targetPermissionSet: targetPermissionSet,
    customPermissions: customPermissions,
  });
}

async function SetCustomerAssignmentPermissions(email: string, permissionSet: string, customPermissions: string[]) {
  return await apiFunctions.post(UserUrls.SetCustomerAssignmentPermissions, {
    targetAccountEmail: email,
    targetPermissionSet: permissionSet,
    customPermissions,
  });
}

async function GetUser(invitationCode?: string): Promise<UserContextApiResponse> {
  return await apiFunctions.post(
    UserUrls.GetUser,
    invitationCode
      ? {
          oneTimeInvitationCode: invitationCode,
        }
      : null,
  );
}

async function GetImpersonateUser(targetEmail: string, targetCustomerId: CustomerId): Promise<UserContextApiResponse> {
  return await apiFunctions.post(UserUrls.GetImpersonateUser, {
    targetEmail,
    targetCustomerId: {
      idType: targetCustomerId.idType,
      idValue: targetCustomerId.idValue,
    },
  });
}

async function SearchUsers(
  query: string,
  page: number,
  pageSize: number,
): Promise<UsersToImpersonateResult['searchUsers']> {
  return await apiFunctions.post(UserUrls.SearchUsers, {
    query,
    page,
    pageSize,
  });
}

async function GetOverrideTemplates(): Promise<User[]> {
  return await apiFunctions.get(UserUrls.GetOverrideTemplates);
}

async function GetFilteredAssignments(filterText: string, page: number, pageSize: number) {
  return await apiFunctions.post(UserUrls.GetFilteredAssignments, {
    filterText,
    page,
    pageSize,
  });
}

export type GetUserManagementParam = {
  filterText: string;
  showInactive: boolean;
  sortBy: string;
  sortDirection: ResultSortDirection;
  page: number;
  pageSize: number;
};

async function GetUserManagementData(param: GetUserManagementParam): Promise<UsersGridResult['userManagementData']> {
  return await apiFunctions.post(UserUrls.GetUserManagementData, param);
}

async function SetActiveIdentifier(idValue: string, idType: string): Promise<boolean> {
  return await apiFunctions.post(UserUrls.SetActiveIdentifier, { customerId: { idType, idValue } });
}

async function UpdateAssignmentStatus(targetEmail: string, isActive: boolean) {
  return await apiFunctions.post(UserUrls.UpdateAssignmentStatus, { targetAccountEmail: targetEmail, isActive });
}

async function SetUserPermissionOverrides(override?: UserContextApiResponse): Promise<boolean> {
  return await apiFunctions.post(UserUrls.SetUserPermissionOverrides, override);
}

async function GetCustomerAddress(): Promise<MDMCustomerAddress> {
  return await apiFunctions.get(UserUrls.getCustomerAddress);
}

async function GetCustomerAccountSettings(): Promise<CustomerAccountSettings> {
  return await apiFunctions.get(UserUrls.getCustomerAccountSettings);
}

async function GetCustomerApplicationStatus(): Promise<Array<ApplicationStatus>> {
  return await apiFunctions.get(UserUrls.getCustomerApplicationStatus);
}

export type InviteValidationResult = {
  emailValidationMessages: string[];
  customerIdValidationMessages: string[];
  resultingPermissionSet: string;
  dbaName: string;
};

export type CustomerAssignmentIdParam = {
  email: string;
  customerId: CustomerId;
};

async function ValidateCustomerAssignmentInvitation(param: CustomerAssignmentIdParam): Promise<InviteValidationResult> {
  return await apiFunctions.post(UserUrls.ValidateCustomerAssignmentInvitation, param);
}

export type InviteCustomerParam = {
  userAccountEmail: string;
  targetAccountEmail: string;
  customerId: CustomerId;
};

async function InviteUserCustomerAssignment(param: InviteCustomerParam): Promise<void> {
  return await apiFunctions.post(UserUrls.InviteUserCustomerAssignment, param);
}

export type CustomerStatusHistory = {
  updatedBy: string;
  active: boolean;
  updatedDate: string;
};

async function GetCustomerAssignmentStatusHistory(param: CustomerAssignmentIdParam): Promise<CustomerStatusHistory> {
  return await apiFunctions.post(UserUrls.GetCustomerAssignmentStatusHistory, param);
}

async function RemoveUserFromCustomer(targetCustomerEmail: string, targetCustomerId: CustomerId): Promise<void> {
  return await apiFunctions.post(UserUrls.RemoveUserFromCustomer, { targetCustomerEmail, targetCustomerId });
}

const userService = {
  GetUsersPermissions,
  SetCustomerAssignmentPermissions,
  GetAllPermissions,
  InviteStaffMember,
  GetUser,
  GetFilteredAssignments,
  GetImpersonateUser,
  GetOverrideTemplates,
  GetUserManagementData,
  SearchUsers,
  SetActiveIdentifier,
  UpdateAssignmentStatus,
  SetUserPermissionOverrides,
  GetCustomerAddress,
  GetCustomerAccountSettings,
  GetCustomerApplicationStatus,
  ValidateCustomerAssignmentInvitation,
  InviteUserCustomerAssignment,
  GetCustomerAssignmentStatusHistory,
  RemoveUserFromCustomer,
};

export default userService;
