import { VegaBox, VegaButton, VegaCard, VegaFlex, VegaFont, VegaIcon } from '@heartlandone/vega-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Trans, useTranslation } from 'next-i18next';

import { helpCenterItem } from 'utility/constants/supportItems';
import { getSupportLinkClickEvent } from 'utility/helper/helperFunctions';
import { DeviceIsMobile } from 'utility/showOnDevice';

const HelpCenterCard: React.FC = () => {
  const appInsights = useAppInsightsContext();
  const isMobile = DeviceIsMobile();

  const { t } = useTranslation('help-center');

  return (
    <VegaCard padding="size-24">
      <VegaFlex direction={isMobile ? 'col-reverse' : 'row'} alignItems="center" gap="size-32">
        <VegaFlex direction="col" style={{ flex: 1 }} gap="size-16">
          <VegaFont variant="font-h5" color="text-primary">
            {t('title')}
          </VegaFont>
          <VegaFont variant="font-field-label" color="text-secondary">
            <Trans
              i18nKey="description"
              t={t}
              components={{ icon: <VegaIcon key="questionCircle" icon="far fa-question-circle" /> }}
            />
          </VegaFont>
          <VegaButton
            variant="primary"
            label={t('visitNow')}
            icon="fa fa-chevron-right"
            iconAlign="right"
            data-testid="help-center-button"
            onVegaClick={() => {
              getSupportLinkClickEvent(helpCenterItem, appInsights);
              window.open(helpCenterItem.actionUrl, '_blank');
            }}
          />
        </VegaFlex>
        <VegaBox>
          <picture>
            <img
              src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/myAccount/help-center.png`}
              alt=""
              style={{ maxHeight: '140px' }}
            />
          </picture>
        </VegaBox>
      </VegaFlex>
    </VegaCard>
  );
};

export default HelpCenterCard;
