import React, { useMemo } from 'react';

import { VegaCard, VegaFlex, VegaFont, VegaLoadingIndicator } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import useTrackerServiceData from 'hooks/equipmentTracker';

import EquipmentHeader from './equipmentHeader';
import EquipmentResourceList from './equipmentResourceList';
import { displayShipmentReferences } from './equipmentTracker.utils';

const EquipmentTracker = () => {
  const { t } = useTranslation('equipment-tracker');
  const { shipmentDetails, fetchingShippingData } = useTrackerServiceData();

  const shouldDisplayEquipmentReferences = useMemo(() => displayShipmentReferences(shipmentDetails), [shipmentDetails]);

  if (fetchingShippingData) {
    return (
      <VegaCard backgroundColor="bg-primary" padding="size-32">
        <VegaFlex direction="col" justifyContent="center" margin="size-16">
          <VegaLoadingIndicator data-testid="loading-shipment-details" mode="indeterminate" />
        </VegaFlex>
      </VegaCard>
    );
  }

  return (
    <VegaCard backgroundColor="bg-primary" padding="size-32">
      <VegaFlex direction="col" margin="size-16">
        <EquipmentHeader shipmentDetails={shipmentDetails} />
        {shouldDisplayEquipmentReferences && (
          <VegaFlex direction="col" margin={{ top: 'size-32', bottom: 'size-16' }}>
            <VegaFont variant="font-h6">{t('resources')}</VegaFont>
            <VegaFont variant="font-p2-short" color="text-secondary">
              {t('references')}
            </VegaFont>
            <VegaFlex margin={{ top: 'size-16', bottom: 'size-16' }}>
              <EquipmentResourceList shipmentDetails={shipmentDetails} />
            </VegaFlex>
          </VegaFlex>
        )}
      </VegaFlex>
    </VegaCard>
  );
};

export default EquipmentTracker;
