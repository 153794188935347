import ShipmentDetails from 'types/equipmentTracker';

export function groupAndCountEquipmentNames(shipments: ShipmentDetails[]): string[] {
  const equipmentCount: Record<string, number> = {};

  for (const shipment of shipments) {
    const name = shipment.equipmentName?.trim();
    if (name) {
      equipmentCount[name] = (equipmentCount[name] || 0) + 1;
    }
  }

  return Object.entries(equipmentCount).map(([name, count]) => (count > 1 ? `${name} (x${count})` : name));
}
