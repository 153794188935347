import { ShipmentDetails } from 'types/equipmentTracker';
import { MyEquipmentListItem } from 'types/myEquipment';

import apiFunctions from '../..';

const myEquipmentBaseUrl = '/api/v1/MyEquipment/';

export const MyEquipmentUrls = {
  getMerchantEquipment: myEquipmentBaseUrl + 'GetMerchantEquipment',
  getUndeliveredEquipmentShipments: myEquipmentBaseUrl + 'GetUndeliveredEquipmentShipments',
};

async function GetMerchantEquipment(
  page: number,
  pageSize: number,
  searchParam?: string,
): Promise<MyEquipmentListItem[]> {
  return await apiFunctions.post(MyEquipmentUrls.getMerchantEquipment, { page, pageSize, searchParam });
}

async function GetUndeliveredEquipmentShipments(): Promise<ShipmentDetails[]> {
  return await apiFunctions.get(MyEquipmentUrls.getUndeliveredEquipmentShipments);
}

const myEquipmentService = {
  GetMerchantEquipment,
  GetUndeliveredEquipmentShipments,
};

export default myEquipmentService;
