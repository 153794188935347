import { useCallback } from 'react';

import { VegaButton, VegaCard, VegaFlex, VegaFont, VegaGrid, VegaIcon } from '@heartlandone/vega-react';
import router from 'next/router';
import { useTranslation } from 'react-i18next';

import { ShipmentDetails } from 'types/equipmentTracker';
import { routes } from 'types/pages';

import { groupAndCountEquipmentNames } from './equipmentHeader.util';

interface EquipmentHeaderProps {
  shipmentDetails: ShipmentDetails[];
}

const EquipmentHeader = ({ shipmentDetails }: EquipmentHeaderProps) => {
  const { t } = useTranslation('equipment-tracker');

  const handleButtonPress = () => router.push(routes.myEquipment);

  const equipmentNames = useCallback(() => groupAndCountEquipmentNames(shipmentDetails), [shipmentDetails])();
  const hasEquipmentInTransit = equipmentNames.length > 0;

  return (
    <VegaCard backgroundColor="bg-quaternary" padding="size-16" variant="border">
      <VegaFlex justifyContent="space-between" direction="row" gap="size-16">
        <VegaFlex alignItems="center" gap="size-16">
          <VegaIcon
            data-testid="delivery-icon"
            icon={hasEquipmentInTransit ? 'fa-solid fa-truck' : 'fa-solid fa-box-open-full'}
            color="text-link"
            size="size-48"
          />
          <VegaGrid row={1} gap="size-4">
            <VegaFont variant="font-h5">
              {hasEquipmentInTransit ? t('equipmentInTransit') : t('equipmentDelivered')}
            </VegaFont>
            <VegaFlex flexWrap="wrap">
              {equipmentNames.map((name, index) => (
                <VegaFont variant="font-p2-short" color="text-secondary" key={name}>
                  {name}
                  {index < equipmentNames.length - 1 && ', '}
                </VegaFont>
              ))}
            </VegaFlex>
          </VegaGrid>
        </VegaFlex>
        <VegaFlex justifyContent="space-between" alignItems="center">
          <VegaButton variant="secondary" icon="fa fa-arrow-right" iconAlign="right" onClick={handleButtonPress}>
            {t('equipmentTrackerButton')}
          </VegaButton>
        </VegaFlex>
      </VegaFlex>
    </VegaCard>
  );
};

export default EquipmentHeader;
