import React from 'react';

import { VegaFlex } from '@heartlandone/vega-react';
import isEmpty from 'lodash/isEmpty';

import { ShipmentDetails } from 'types/equipmentTracker';

import EquipmentResourceCard from '../equipmentResourceCard';

interface EquipmentResourceListProps {
  shipmentDetails: ShipmentDetails[];
}

const EquipmentResourceList = ({ shipmentDetails }: EquipmentResourceListProps) => {
  const filteredShipments = shipmentDetails.filter((item) => !isEmpty(item.helpArticleUrl));

  return (
    <VegaFlex direction="row" flexWrap="wrap">
      {filteredShipments.map((item) => (
        <VegaFlex margin={{ right: 'size-16', bottom: 'size-16' }} key={`${item.equipmentName}-${item.tid}`}>
          <EquipmentResourceCard
            equipmentName={item.equipmentName}
            resourceLink={item.helpArticleUrl!}
            dateShipped={item.dateShipped}
          />
        </VegaFlex>
      ))}
    </VegaFlex>
  );
};

export default EquipmentResourceList;
